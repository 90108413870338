/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import React, { useEffect, useRef, useState } from "react";
import MaterialTablePicker from "components/MaterialTablePicker";

import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, fetchStoreItems, updateProduct } from "store/actions/locationAction";

import MDBox from "components/MDBox";
// import AddStoreProduct from "./AddStoreProduct";
import EditStoreProduct from "./EditStoreProduct";
import ConfigureSettings from "./ConfigureSettings";
import ProductForm from "./ProductForm";

const addIcon = () => <AddIcon />;
const settingsIcon = () => <SettingsIcon />;

const OnlineStore = ({ locationId, settingsConfigs }) => {
  /* ** Refs ** */
  const tableRef = useRef(null);
  const initialValues = useRef({});
  const productDetails = useRef({});

  /* ** States ** */
  const [iconPressed, setIconPressed] = useState("");

  /* ** Selectors ** */
  const store = useSelector((state) => state.locations.store);
  const productUpdated = useSelector((state) => state.locations.productUpdated);
  const user = useSelector((state) => state.users.user);

  /* ** Constants ** */
  const dispatch = useDispatch();
  const materialTableIcons = [
    {
      icon: settingsIcon,
      isFreeAction: true,
      tooltip: "Settings",
      onClick: () => handleIconPressed("settings", {}),
      iconProps: {
        style: {
          color: "black",
          fontSize: "25px",
          boxShadow: "none",
          marginLeft: "10px",
        },
      },
    },
    {
      icon: addIcon,
      isFreeAction: true,
      tooltip: "Add Product",
      onClick: () => handleIconPressed("add", {}),
      iconProps: {
        style: {
          color: "black",
          fontSize: "25px",
          boxShadow: "none",
          marginLeft: "10px",
        },
      },
    },
  ];

  useEffect(() => {
    if (locationId) {
      dispatch(fetchStoreItems(locationId));
    }
  }, [locationId]);
  useEffect(() => {
    if (productUpdated) {
      dispatch(fetchStoreItems(locationId));
    }
  }, [productUpdated]);

  const handleIconPressed = (icon) => {
    if (iconPressed === icon) {
      setIconPressed("");
      return;
    }

    if (icon === "add") {
      initialValues.current = {
        tag: {},
        price: "",
        subTag: {},
        currency: "",
        productName: "",
        productCode: "",
      };
    } else {
      initialValues.current = {
        staffEmail: "",
        orderProcessingType: [{ name: "emailToCs", label: "Email to CS" }],
        paymentMethod: [
          { name: "cash", label: "Cash On Delivery (COD)" },
          { name: "credit", label: "Credit Card" },
          { name: "charges", label: "Room Charges" },
        ],
      };
    }

    setIconPressed(icon);
  };

  const deletePartnerFn = (id) => {
    dispatch(deleteProduct(id));
  };

  const updatePartnerFn = (data) => {
    dispatch(updateProduct(data, data?._id));
  };

  if (!iconPressed) {
    return (
      <MaterialTablePicker
        screen="OnlineStoreList"
        title=""
        isSearchable
        columns={["Product Name", "Product Code", " Currency", " Price", "Tag ", "Subtag"]}
        data={store}
        tableRef={tableRef}
        actionsName="onlineStore"
        isExportable={false}
        // addUrl="/add-product"
        searchField="elements"
        filteringOptions={false}
        customActions={materialTableIcons}
        onRowClickFn={(rowData) => {
          setIconPressed("edit");
          productDetails.current = rowData;
        }}
        editableUpdate={updatePartnerFn}
        editableDelete={deletePartnerFn}
        updateRoleAction
        deleteRoleAction
        editableRole={user?.role === "admin" || user?.role === "partner"}
        actionsRole={user?.role === "admin" || user?.role === "partner"}
      />
    );
  }

  return (
    <MDBox shadow="md" bgColor="white" borderRadius="md" variant="contained">
      {iconPressed === "add" && (
        <ProductForm setIconPressed={setIconPressed} locationId={locationId} />
      )}

      {iconPressed === "settings" && (
        <ConfigureSettings
          locationId={locationId}
          setIconPressed={setIconPressed}
          settingsConfigs={settingsConfigs}
        />
      )}

      {iconPressed === "edit" && (
        <EditStoreProduct
          locationId={locationId}
          productDetails={productDetails}
          setIconPressed={setIconPressed}
        />
      )}
    </MDBox>
  );
};

export default OnlineStore;

OnlineStore.propTypes = {
  locationId: PropTypes.string.isRequired,
  // locationCurrency: PropTypes.string.isRequired,
  settingsConfigs: PropTypes.instanceOf(Object).isRequired,
};

/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTable from "components/Tables/DataTable";

import {
  Dialog,
  Select,
  MenuItem,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
} from "@mui/material";
import MDButton from "components/MDButtonLoading";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "store/actions/statsActions";
import {
  fetchItemQuote,
  fetchCancelReasons,
  cancelItem,
  clearItemQuote,
  markAsReadFn,
} from "store/actions/orderAction";
import Spinner from "components/Spinner";

const OrdersDataTable = ({ locationId = "" }) => {
  /* **** Selectors **** */
  const rowId = useRef("");

  /* **** Selectors **** */
  const user = useSelector((state) => state.users.user);
  const orders = useSelector((state) => state.stats.orders);
  const quotes = useSelector((state) => state.order.quotes);
  const quotesLoading = useSelector((state) => state.order.itemQuotesLoading);

  const reasons = useSelector((state) => state.order.reasons);
  const reasonsLoading = useSelector((state) => state.order.reasonLoading);

  const refunded = useSelector((state) => state.order.refunded);
  const refundedLoading = useSelector((state) => state.order.refundedLoading);
  const order = useSelector((state) => state.order.order);

  /* **** Constants **** */
  const dispatch = useDispatch();
  const { currencyCode, itemPrice, refundAmount, refundPercentage } = quotes.refundDetails || {};

  /* **** States **** */
  const [open, setOpen] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");

  const columns = [
    { Header: "Title", accessor: "title", width: "30%" },
    { Header: "Email", accessor: "email", width: "20%" },
    { Header: "Date", accessor: "date", width: "10%" },
    { Header: "Type", accessor: "type", width: "10%" },
    { Header: "Source", accessor: "source", width: "13%" },
    { Header: "Price ($)", accessor: "price", width: "13%" },
    { Header: "Status", accessor: "status", width: "17%" },
    {
      Header: "Actions",
      accessor: "actions",
      width: "10%",
      Cell: ({ row }) => handleActionBttn(row),
    },
  ];

  const [state, setState] = useState({
    columns,
    rows: [],
  });

  useEffect(() => {
    setTimeout(2000);
    if (locationId) {
      dispatch(getOrders(locationId));
    } else {
      dispatch(getOrders(""));
    }

    return () => {
      dispatch(clearItemQuote());
    };
  }, []);

  useEffect(() => {
    if (orders?.length > 0) {
      const transformedData = orders?.map((item) => {
        const { type, itemSource, itemType, status } = item;

        // const currentTime = Date.now();
        // const isRefunded = type === "refund";

        // const travelDateTime = new Date(item?.travelDate.split("/").reverse().join("-")).getTime();
        // const hasTravelDatePassed = currentTime > travelDateTime;
        // const isWithin24Hours =
        //   (travelDateTime - currentTime) / (1000 * 60 * 60) <= 24 &&
        //   travelDateTime - currentTime >= 0;
        // console.log("itemmmm", item);
        // const nonRefundable =
        //   !isRefunded &&
        //   (isWithin24Hours ||
        //     item?.cancellationPolicy.type === "ALL_SALES_FINAL" ||
        //     hasTravelDatePassed);

        let displayType = type;
        if (itemSource === "lokalee" && itemType === "Item") {
          displayType = "Sellable Item";
        } else if (itemSource === "viator") {
          displayType = "Viator Ticket";
        } else {
          displayType = "Lokalee Ticket";
        }

        // Determine the display text based on the button status
        let paymentStatus = "";
        if (displayType === "Sellable Item") {
          if (status === "succeeded") {
            if (item?.paymentMethod === "cash") {
              paymentStatus = "Paid with Cash on Delivery";
            } else if (item?.paymentMethod === "stripe") {
              paymentStatus = "Paid with Credit Card";
            } else {
              paymentStatus = "Paid with Room Charges";
            }
          } else {
            paymentStatus = "Pending";
          }
        } else if (type === "refund") {
          paymentStatus = "Refunded";
        } else {
          paymentStatus = "Paid with Credit Card";
        }
        if (status === "cancelled") {
          paymentStatus = "Cancelled from Viator";
        }

        return {
          title: item.title,
          email: item.email,
          date: item.date,
          type: displayType,
          source: item?.source,
          itemSource,
          itemType,
          price: item.total?.toFixed(2),
          travelDate: item.travelDate,
          cancellationPolicy: item.cancellationPolicy,
          status: paymentStatus,
          statusFromBackend: item.status,
          actions: () => {
            rowId.current = item.rowId;
            if (displayType === "Sellable Item") {
              dispatch(markAsReadFn(item.id));
            } else {
              dispatch(fetchItemQuote(item.rowId));
              dispatch(fetchCancelReasons());
            }
          },
        };
      });

      setState({ ...state, rows: transformedData });
    } else {
      setState({ ...state, rows: [] });
    }
  }, [orders]);

  useEffect(() => {
    if (Object.keys(refunded).length > 0 && refunded.status === "refunded" && cancellationReason) {
      setOpen(false);
      setCancellationReason("");
      if (locationId) {
        dispatch(getOrders(locationId));
      } else {
        dispatch(getOrders(""));
      }
    }
    if (order?.data) {
      if (locationId) {
        dispatch(getOrders(locationId));
      } else {
        dispatch(getOrders(""));
      }
    }
  }, [refunded, order?.data]);

  useEffect(() => {
    if (Object.keys(quotes).length > 0 && reasons.length > 0) {
      setOpen(true);
    }
  }, [quotes, reasons]);

  const handleActionBttn = (row) => {
    const {
      actions,
      type = "",
      travelDate = "",
      cancellationPolicy = { type: "" },
      itemSource = "",
      itemType = "",
      item = "",
      statusFromBackend,
    } = row.original || {};

    const baseStyle = {
      color: "white",
      height: "30px",
      width: "80px",
      display: "flex",
      outline: "none",
      boxShadow: "none",
      borderRadius: "5px",
      alignItems: "center",
      justifyContent: "center",
      transition: "background 0.3s, color 0.3s",
    };
    const styles = {
      refund: {
        ...baseStyle,
        cursor: "pointer",
        background: "green",
        borderColor: "green",
      },
      refundedDisabled: {
        ...baseStyle,
        opacity: "0.5",
        background: "green",
        borderColor: "green",
        cursor: "not-allowed",
      },
      markAsReadDisabled: {
        ...baseStyle,
        opacity: "0.5",
        background: "green",
        borderColor: "green",
        cursor: "not-allowed",
      },
      hover: {
        color: "white",
        background: "darkgreen",
      },
    };

    const currentTime = Date.now();
    const isRefunded = type === "refund";
    const isMarkAsReadDisabled =
      itemSource === "lokalee" && statusFromBackend === "succeeded" && itemType === "Item";
    const travelDateTime = new Date(travelDate.split("/").reverse().join("-")).getTime();
    const hasTravelDatePassed = currentTime > travelDateTime;

    const isWithin24Hours =
      (travelDateTime - currentTime) / (1000 * 60 * 60) <= 24 && travelDateTime - currentTime >= 0;
    const nonRefundable =
      !isRefunded &&
      (isWithin24Hours ||
        cancellationPolicy.type === "ALL_SALES_FINAL" ||
        (hasTravelDatePassed && item === "Sellable Item"));
    const label = isRefunded ? "Refunded" : "Refund";
    const style = statusFromBackend === "refunded" ? styles.refundedDisabled : styles.refund;
    const markAsReadstyle = isMarkAsReadDisabled ? styles.markAsReadDisabled : styles.refund;

    if (
      (statusFromBackend === "cancelled" && user?.role === "admin") ||
      (!isRefunded && nonRefundable)
    ) {
      return (
        <p
          style={{
            margin: "0",
            color: "black",
            width: "80px",
            fontSize: "12px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          N/A
        </p>
      );
    }

    if (type === "Sellable Item") {
      return (
        <button
          type="button"
          style={markAsReadstyle}
          onClick={actions}
          disabled={statusFromBackend === "succeeded"}
          aria-label="Mark as paid"
          onMouseEnter={(e) => {
            e.target.style.background = styles.hover.background;
          }}
          onMouseLeave={(e) => {
            e.target.style.background = styles.refund.background;
          }}
        >
          Mark as paid
        </button>
      );
    }
    if (user?.role === "admin") {
      return (
        <button
          type="button"
          style={style}
          onClick={actions}
          disabled={statusFromBackend === "refunded"}
          aria-label="Click to refund"
          onMouseEnter={(e) => {
            e.target.style.background = styles.hover.background;
          }}
          onMouseLeave={(e) => {
            e.target.style.background = styles.refund.background;
          }}
        >
          {label}
        </button>
      );
    }
    return null;
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item size={{ xs: 12 }}>
          <Card>
            <MDBox pt={3} px={3}>
              <MDTypography variant="h6" fontWeight="medium">
                Orders
              </MDTypography>
            </MDBox>
            <MDBox py={1}>
              <DataTable
                canSearch
                key={user.role}
                table={state}
                entriesPerPage
                showTotalEntries
                isSorted
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title" sx={{ pb: 1 }}>
          Refund Order
        </DialogTitle>

        <DialogContent>
          <MDBox sx={{ px: 3, py: 1 }}>
            <MDTypography variant="body2" gutterBottom>
              <strong>Item Price:</strong> {currencyCode} {itemPrice}
            </MDTypography>
            <MDTypography variant="body2" gutterBottom>
              <strong>Refund Amount:</strong> {currencyCode} {refundAmount}
            </MDTypography>
            <MDTypography variant="body2" gutterBottom>
              <strong>Refund Percentage:</strong> {refundPercentage}%
            </MDTypography>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="reason-select-label">Select Cancellation Reason</InputLabel>
              <Select
                labelId="reason-select-label"
                label="Select Cancellation Reason"
                value={cancellationReason || ""}
                onChange={(e) => {
                  setCancellationReason(e.target.value);
                }}
              >
                {reasons?.map((reason) => (
                  <MenuItem key={reason._id} value={reason.cancellationReasonCode}>
                    {reason.cancellationReasonText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
        </DialogContent>

        <DialogActions sx={{ px: 3, py: 2 }}>
          <MDButton onClick={() => setOpen(false)} autoFocus>
            No
          </MDButton>

          <MDButton
            disabled={!cancellationReason}
            onClick={() => {
              dispatch(cancelItem(rowId.current, cancellationReason));
            }}
          >
            Yes
          </MDButton>
        </DialogActions>
      </Dialog>

      <Spinner loading={quotesLoading || reasonsLoading || refundedLoading} />
    </div>
  );
};

// Typechecking props for OrdersDataTable
OrdersDataTable.propTypes = {
  locationId: PropTypes.string,
};

export default OrdersDataTable;

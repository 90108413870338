/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { EditorState, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import TranslateIcon from "@mui/icons-material/Translate";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TokenIcon from "@mui/icons-material/Token";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicHeader from "components/DynamicHeader";
import ViewEditActions from "components/DetailList/ViewEditActions";
import DynamicTranslate from "components/DynamicTranslate";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions
import {
  updateRecommendation,
  clearErrors,
  getOneRecommendation,
  updateRecommendationTranslations,
  setLoading,
} from "store/actions/recommendationActions";
import setAlert from "store/actions/alertActions";
import {
  setLoading as setVoucherLoading,
  getRecommednationVoucher,
} from "store/actions/voucherActions";

// Schemas
import CardsList from "components/CardsList";
import { clearErrors as clearCityErrors } from "store/actions/cityActions";
import { resetFormToInitialState } from "components/helper";
import validations from "./schemas/validations";
import form from "../new-recommendation/schemas/form";
import initialValues from "../new-recommendation/schemas/initialValues";
import RecommendationDetail from "./components/RecommendationDetail";

const EditRecommendation = () => {
  const { formId, formField } = form;
  const { recommendation_id } = useParams();

  const formRef = useRef();

  const firstRunExecuted = useRef(false); // Tracks if the first run has been executed
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const recommendation = useSelector((state) => state.recommendations.recommendation);
  const error = useSelector((state) => state.recommendations.error);
  const added = useSelector((state) => state.recommendations.added);
  const user = useSelector((state) => state.users.user);
  const vouchers = useSelector((state) => state?.vouchers?.vouchers);
  const vouchersLoading = useSelector((state) => state?.vouchers?.loading);

  // state
  const [initialState, setInitialState] = useState(initialValues);
  const [image, _setImage] = useState(recommendation?.logo);
  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [formRefVal, setFormRefVal] = useState(null);
  const [recommendationDetailPage, setRecommendationDetailPage] = useState(true);
  const [dateAndTimeDetailPage, setDateAndTimeDetailPage] = useState(true);
  const [additionalDetailPage, setAdditionalDetailPage] = useState(true);
  const [addressDetailPage, setAddressDetailPage] = useState(true);
  const [tripPlannerDetailPage, setTripPlannerDetailPage] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [translateItems, setTranslateItems] = useState([]);
  const [selectedPrimaryDestination, setSelectedPrimaryDestination] = useState(null);
  const [selectedSecondaryDestination, setSelectedSecondaryDestination] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [firstRun, setFirstRun] = useState(false);

  const detailTabs = [
    { id: 1, title: "RECOMMENDATION DETAILS", iconComponent: AssignmentIcon },
    { id: 2, title: "VOUCHERS", iconComponent: TokenIcon },
    { id: 3, title: "TRANSLATE", iconComponent: TranslateIcon },
  ];

  const currentValidation = !recommendationDetailPage
    ? validations[0]
    : !addressDetailPage
    ? validations[1]
    : !additionalDetailPage
    ? validations[2]
    : !dateAndTimeDetailPage
    ? validations[3]
    : !tripPlannerDetailPage
    ? validations[4]
    : undefined;

  useEffect(() => {
    if (!recommendation) return;
    const formatOpenHours = (hours) => {
      const hasTimeRange = !hours.open_all_day && !hours.closed;

      if (hasTimeRange) {
        return [{ _id: 0, open: hours.open, close: hours.close }];
      }

      return [];
    };
    const manipulated = {
      _id: recommendation?._id || null,
      top: recommendation?.top || false,
      title: recommendation?.title || "",
      style: recommendation?.style || [],
      email: recommendation?.email || "",
      phone: recommendation?.phone || "",
      price: recommendation?.price || "",
      location: recommendation?.location || "",
      features: recommendation?.features || "",
      cuisines: recommendation?.cuisines || [],
      special: recommendation?.special || false,
      insider: recommendation?.insider || false,
      interests: recommendation?.interests || [],
      subHeader: recommendation?.subHeader || "",
      currated: recommendation?.currated || false,
      badges: recommendation?.badges?.[0] || null,
      highlight: recommendation?.highlight || false,
      exclusive: recommendation?.exclusive || false,
      sponsored: recommendation?.sponsored || false,
      description: recommendation?.description || "",
      seasonality: recommendation?.seasonality || "",
      webAddresses: recommendation?.webAddresses || "",
      callToAction: recommendation?.callToAction || {},
      city: recommendation?.geoLocationData?.city || "",
      experienceX: recommendation?.experienceX || false,
      callToAction2: recommendation?.callToAction2 || {},
      kidsFriendly: recommendation?.kidsFriendly || false,
      traveltype: recommendation?.traveltype || "leisure",
      region: recommendation?.geoLocationData?.region || "",
      bookable: !!recommendation?.reservation?.bookingEmail,
      maxGuests: recommendation?.reservation?.maxGuests || "",
      minGuests: recommendation?.reservation?.minGuests || "",
      country: recommendation?.geoLocationData?.country || "",
      address: recommendation?.geoLocationData?.address || "",
      genderPreference: recommendation?.genderPreference || "",
      lat: recommendation?.geoLocation?.coordinates?.[1] || "",
      lng: recommendation?.geoLocation?.coordinates?.[0] || "",
      exclusivePartner: recommendation?.exclusivePartner || false,
      primaryDestination: recommendation?.primaryDestination || "",
      timeSlotPreference: recommendation?.timeSlotPreference || "",
      exclusiveLocation: recommendation?.exclusiveLocation || false,
      bookingEmail: recommendation?.reservation?.bookingEmail || "",
      images: recommendation?.images?.length > 0 ? recommendation?.images : [],
      needsConfirmation: recommendation?.reservation?.needsConfirmation || false,
      destinations:
        recommendation?.destinations?.map((destination) => ({
          label: destination?.destinationName,
          value: destination?._id,
          type: destination?.destinationType,
        })) || [],
      tags:
        recommendation?.tags?.map((tag) => ({
          label: tag?.title,
          value: tag?._id,
        })) || [],
      // Formate the data to group the open and close hours by week day
      openHours: recommendation.openHours
        ? recommendation.openHours.reduce((acc, item) => {
            const { open, close, weekDay, ...rest } = item;

            const formattedHours = formatOpenHours(item);

            const existingDay = acc.find((entry) => entry.weekDay === weekDay);

            if (existingDay) {
              existingDay.openHours = [...existingDay.openHours, ...formattedHours];
            } else {
              acc.push({
                weekDay,
                ...rest,
                openHours: formattedHours,
              });
            }

            return acc;
          }, [])
        : [],
    };

    setInitialState(manipulated);
    setSelectedTags(recommendation?.tags);
    setSelectedLocation(recommendation?.location);
    _setImage(recommendation?.logo || recommendation?.cover_image_url);

    const arTranslation = recommendation?.translations?.ar;
    const arr = [
      {
        id: 1,
        name: "title",
        title: "Title",
        value: arTranslation?.title || "",
        default: "",
        label: "Title",
      },
      {
        id: 2,
        name: "subHeader",
        title: "SubHeader",
        value: arTranslation?.subHeader || "",
        default: "",
        label: "SubHeader",
      },
      {
        id: 3,
        name: "description",
        title: "Description",
        value: arTranslation?.description || "",
        default: "",
        label: "Description",
      },
    ];
    setTranslateItems(arr);
  }, [recommendation]);

  useEffect(() => {
    setFirstRun(true);
  }, []);

  useEffect(() => {
    if (formRefVal?.city) {
      dispatch(clearCityErrors());
    }
  }, [formRefVal, formRefVal?.city]);

  useEffect(() => {
    // Execute only at the first run
    if (recommendation_id && firstRun && !firstRunExecuted.current) {
      dispatch(getOneRecommendation(recommendation_id));
      firstRunExecuted.current = true; // Mark the first run as executed
      return; // Exit to prevent other conditions in this run
    }

    // Execute when `added` becomes true
    if (recommendation_id && added) {
      dispatch(getOneRecommendation(recommendation_id));
    }
  }, [recommendation_id, added, firstRun]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
      // Reset the form to its initial state
      resetFormToInitialState(formRef, initialState);
    }
  }, [error, setAlert, clearErrors]);

  useEffect(() => {
    if (recommendation?.logo) {
      _setImage(recommendation?.logo);
    }
  }, [recommendation?.logo]);

  const urlToBinary = async (url) => {
    const response = await fetch(`${url}?additional-param`);
    if (!response.ok) {
      throw new Error(
        `Failed to fetch image from ${url}. Status: ${response.status} ${response.statusText}`
      );
    }
    const arrayBuffer = await response.arrayBuffer();
    return new Uint8Array(arrayBuffer);
  };
  const blobToBinary = async (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(new Uint8Array(reader.result));
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  const handleTagsChange = (val) => {
    if (val) {
      setSelectedTags(val);
    } else {
      setSelectedTags([]);
    }
  };

  const convertImage = async (source) => {
    try {
      let binaryData;
      if (source instanceof File || typeof source === "object") {
        // Source is a File object
        const blob = await blobToBinary(source);
        binaryData = blob;
      } else if (typeof source === "string" && source.startsWith("data:image")) {
        // Source is a URL
        binaryData = source;
      } else if (typeof source === "string" && source.startsWith("https://")) {
        // Source is a URL
        binaryData = await urlToBinary(source);
      } else {
        throw new Error("Unsupported image source type");
      }

      // Create a File object
      const fileName = source instanceof File ? source.name : "defaultImage.jpg";
      const file = new File([binaryData], fileName, { type: "image/jpeg" }); // Update type accordingly

      return file;
    } catch (err) {
      throw error;
    }
  };
  const processImages = async (imageSources) => {
    try {
      const binaryImages = await Promise.all(imageSources.map(convertImage));
      return binaryImages;
    } catch (err) {
      throw error;
    }
  };
  const submitForm = async (values) => {
    try {
      dispatch(setLoading());

      // Remove deleted images from the recommendation?.images array
      const updatedImages = values.images.filter((newImage) => !newImage.deleted);

      // Process the updated images as binary data
      const processedImages = await processImages(updatedImages);

      // Update the values with the processed images
      const updatedValues = { ...values, images: processedImages };

      if (updatedValues?.title === recommendation?.title) delete updatedValues?.title;
      if (updatedValues?.bookable) {
        delete updatedValues.bookable;
      } else {
        updatedValues.reservation = false;
        delete updatedValues?.bookable;
        delete updatedValues?.bookingEmail;
        delete updatedValues?.maxGuests;
        delete updatedValues?.minGuests;
        delete updatedValues?.needsConfirmation;
      }

      updatedValues.openHours = updatedValues.openHours.flatMap((item) => {
        if (item.openHours.length > 0) {
          return item.openHours.map((time) => ({
            open: time.open,
            close: time.close,
            closed: item.closed,
            weekDay: item.weekDay,
            open_all_day: item.open_all_day,
          }));
        }

        return {
          open: null,
          close: null,
          weekDay: item.weekDay,
          closed: item.closed,
          open_all_day: item.open_all_day,
        };
      });

      dispatch(updateRecommendation(updatedValues, values?.logo));

      setRecommendationDetailPage(true);
      setAdditionalDetailPage(true);
      setAddressDetailPage(true);
      setDateAndTimeDetailPage(true);
      setTripPlannerDetailPage(true);

      dispatch(setLoading());
    } catch (err) {
      // Handle the error appropriately, e.g., show a message to the user
      dispatch(setLoading());
    }
  };
  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };
  const handleSubmitTranslate = (values) => {
    dispatch(updateRecommendationTranslations(values, recommendation?._id));
  };
  const handleDetailData = () => {
    switch (tabValue) {
      case 1:
        return (
          <CardsList data={vouchers} loading={vouchersLoading} url={`/${user.role}/vouchers`} />
        );
      case 2:
        return (
          <DynamicTranslate
            items={translateItems}
            handleSubmit={handleSubmitTranslate}
            id={recommendation?._id}
          />
        );

      default:
        return null;
    }
  };
  const handleLocationChange = (value) => {
    if (value && value?._id) {
      setSelectedLocation(value);
    } else {
      setSelectedLocation({});
    }
  };
  const handlePrimaryDestinationChange = (value) => {
    if (!value || !value.label) {
      setSelectedPrimaryDestination({});
    } else {
      setSelectedPrimaryDestination(value);
    }
  };
  const handleSecondaryDestinationChange = (value) => {
    if (value) {
      setSelectedSecondaryDestination(value);
    } else {
      setSelectedSecondaryDestination([]);
    }
  };
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOneRecommendation(recommendation_id));
    }
    if (newValue === 1) {
      dispatch(setVoucherLoading());
      dispatch(getRecommednationVoucher(recommendation_id));
    }
  };
  function getStepContent(type, formData) {
    let detailPage;
    let setDetailPage;

    switch (type) {
      case "recommendationInfo":
        detailPage = recommendationDetailPage;
        setDetailPage = setRecommendationDetailPage;
        break;
      case "address":
        detailPage = addressDetailPage;
        setDetailPage = setAddressDetailPage;
        break;
      case "dateAndTimeInfo":
        detailPage = dateAndTimeDetailPage;
        setDetailPage = setDateAndTimeDetailPage;
        break;

      default:
        detailPage = additionalDetailPage;
        setDetailPage = setAdditionalDetailPage;
    }

    const handleEdit = () => {
      formData.resetForm();
      if (type === "recommendationInfo") {
        setRecommendationDetailPage(false);
        setAddressDetailPage(true);
        setAdditionalDetailPage(true);
        setDateAndTimeDetailPage(true);
        setTripPlannerDetailPage(true);
      }
      if (type === "address") {
        setAddressDetailPage(false);
        setRecommendationDetailPage(true);
        setAdditionalDetailPage(true);
        setDateAndTimeDetailPage(true);
        setTripPlannerDetailPage(true);
      }
      if (type === "additionalInfo") {
        setAdditionalDetailPage(false);
        setRecommendationDetailPage(true);
        setAddressDetailPage(true);
        setDateAndTimeDetailPage(true);
        setTripPlannerDetailPage(true);
      }
      if (type === "dateAndTimeInfo") {
        setDateAndTimeDetailPage(false);
        setAdditionalDetailPage(true);
        setRecommendationDetailPage(true);
        setAddressDetailPage(true);
        setTripPlannerDetailPage(true);
      }

      setImgDetailPage(true);
    };

    return (
      <RecommendationDetail
        type={type}
        formData={formData}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        handleEdit={handleEdit}
        selectedLocation={selectedLocation}
        handleLocationChange={handleLocationChange}
        handlePrimaryDestinationChange={handlePrimaryDestinationChange}
        handleSecondaryDestinationChange={handleSecondaryDestinationChange}
        selectedPrimaryDestination={selectedPrimaryDestination}
        selectedSecondaryDestination={selectedSecondaryDestination}
        setSelectedPrimaryDestination={setSelectedPrimaryDestination}
        handleTagsChange={handleTagsChange}
        selectedTags={selectedTags}
      />
    );
  }
  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/recommendations?`;
      navigate(url);
    } else if (type === "partner") {
      url = `/partner/recommendations?locationId=${recommendation?.location?._id}`;
      navigate(url);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                setFormRefVal(f?.values);
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <DynamicHeader
                      user={recommendation}
                      image={image}
                      imgDetailPage={imgDetailPage}
                      detailTabs={detailTabs}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                      imageType="logo"
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      {" "}
                      {tabValue === 0 && (
                        <ViewEditActions
                          detailPage={imgDetailPage}
                          stateName="recommendations"
                          handleEdit={() => {
                            setImgDetailPage(false);
                            setDateAndTimeDetailPage(true);
                            setAdditionalDetailPage(true);
                            setRecommendationDetailPage(true);
                            setAddressDetailPage(true);
                            setTripPlannerDetailPage(true);
                          }}
                          handleClose={() => {
                            _setImage(recommendation?.logo);
                            setImgDetailPage(true);
                          }}
                          handleSave={() => {
                            submitForm(values);
                            setImgDetailPage(true);
                          }}
                        />
                      )}
                    </DynamicHeader>
                    {tabValue === 0 ? (
                      <>
                        {["recommendationInfo", "address", "additionalInfo", "dateAndTimeInfo"].map(
                          (item, index) => (
                            <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                              <MDBox p={2}>
                                <Grid container spacing={3} alignItems="center">
                                  <Grid item size={{ xs: 12 }}>
                                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                                      {getStepContent(item, {
                                        values,
                                        touched,
                                        formField,
                                        errors,
                                        setFieldValue,
                                        resetForm,
                                      })}
                                    </MDBox>
                                  </Grid>
                                </Grid>
                              </MDBox>
                            </Card>
                          )
                        )}
                      </>
                    ) : (
                      handleDetailData()
                    )}
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};

export default EditRecommendation;

/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { EditorState, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import TranslateIcon from "@mui/icons-material/Translate";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useHandleError, dateFormatWithDash } from "components/helper";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicHeader from "components/DynamicHeader";
import ViewEditActions from "components/DetailList/ViewEditActions";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions

import {
  updatePromotion,
  getOnePromotion,
  clearErrors,
  updatePromotionTranslations,
  setLoading,
  getPromotionTranslations,
} from "store/actions/promotionActions";
import setAlert from "store/actions/alertActions";

// Schemas
import DynamicTranslate from "components/DynamicTranslate";

import validations from "./schemas/validations";
import form from "../new-promotion/schemas/form";
import initialValues from "../new-promotion/schemas/initialValues";
import PromotionDetail from "./components/PromotionDetail";

const EditPromotion = () => {
  const { formId, formField } = form;
  const { promotion_id } = useParams();

  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const promotion = useSelector((state) => state.promotions.promotion);
  const error = useSelector((state) => state.promotions.error);
  const added = useSelector((state) => state.promotions.added);
  const user = useSelector((state) => state.users.user);
  const translationPromotion = useSelector((state) => state.promotions.translationPromotion);

  // state
  const [initialState, setInitialState] = useState(initialValues);
  const [image, _setImage] = useState(promotion?.logo);
  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [dateAndTimeDetailPage, setDateAndTimeDetailPage] = useState(true);
  const [selectedRecommendation, setSelectedRecommendation] = useState(null);
  const [promotionDetailPage, setPromotionDetailPage] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [promotionValidation, setPromotionValidation] = useState(validations[0]);
  const [tabValue, setTabValue] = useState(0);
  const [translateItems, setTranslateItems] = useState([]);

  const detailTabs = [
    { id: 1, title: "DETAILS", iconComponent: AssignmentIcon },
    { id: 2, title: "TRANSLATE", iconComponent: TranslateIcon },
  ];

  // useEffect
  useEffect(() => {
    if (promotion_id && detailTabs[1]?.id === 2) {
      dispatch(getPromotionTranslations(promotion_id));
    }
  }, [detailTabs[1]?.id, promotion_id]);

  useEffect(() => {
    if (!promotionDetailPage) {
      setPromotionValidation(validations[0]);
    } else if (!dateAndTimeDetailPage) {
      setPromotionValidation(validations[1]);
    } else {
      setPromotionValidation(undefined);
    }
  }, [promotionDetailPage, dateAndTimeDetailPage]);

  useEffect(() => {
    if (promotion_id) {
      dispatch(getOnePromotion(promotion_id));
    }
  }, [promotion_id, added]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (promotion) {
      const arr = [
        {
          id: 1,
          name: "title",
          title: "Title",
          value: translationPromotion?.title || "",
          default: "",
          label: "Title",
        },
        {
          id: 2,
          name: "summary",
          title: "Summary",
          value: translationPromotion?.summary || "",
          default: "",
          label: "Summary",
        },
        {
          id: 3,
          name: "description",
          title: "Description",
          value: translationPromotion?.description || "",
          default: "",
          label: "Description",
        },
      ];
      setTranslateItems(arr);
    }
  }, [promotion, translationPromotion]);

  useEffect(() => {
    if (promotion?.logo) {
      _setImage(promotion?.logo);
    }
  }, [promotion?.logo]);
  // We cant use the promotion as is because of address picker and map picker
  // const [originalTitle, setOriginalTitle] = useState("");

  useEffect(() => {
    if (promotion) {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      if (!promotion) return;
      const formatOpenHours = (hours) => {
        const hasTimeRange = !hours.open_all_day && !hours.closed;

        if (hasTimeRange) {
          return [{ _id: 0, open: hours.open, close: hours.close }];
        }

        return [];
      };

      const manipulated = {};
      manipulated.title = promotion.title ? promotion.title : "";
      manipulated.summary = promotion.summary ? promotion.summary : "";
      manipulated.description = promotion.description ? promotion.description : "";
      manipulated.startDate = promotion?.startDate
        ? dateFormatWithDash(promotion?.startDate)
        : dateFormatWithDash(today);
      manipulated.endDate = promotion?.endDate
        ? dateFormatWithDash(promotion?.endDate)
        : dateFormatWithDash(tomorrow);

      manipulated.location = promotion.location ? promotion.location : {};
      manipulated.recommendation = promotion.recommendation ? promotion.recommendation : {};
      manipulated.locRec = {
        location: manipulated.location,
        recommendation: manipulated.recommendation,
      };
      manipulated.minCover = promotion.minCover ? promotion.minCover : 0;
      manipulated.maxCover = promotion.maxCover ? promotion.maxCover : 0;
      manipulated.needsConfirmation =
        promotion.needsConfirmation !== undefined
          ? promotion?.source === "lokalee"
            ? promotion.needsConfirmation
            : false
          : true;
      manipulated.openEnded = promotion.openEnded ? promotion.openEnded : false;
      manipulated.bookingEmail = promotion.bookingEmail ? promotion.bookingEmail : "";

      manipulated.openHours = promotion.openHours
        ? promotion.openHours.reduce((acc, item) => {
            const { open, close, weekDay, ...rest } = item;

            const formattedHours = formatOpenHours(item);

            const existingDay = acc.find((entry) => entry.weekDay === weekDay);

            if (existingDay) {
              existingDay.openHours = [...existingDay.openHours, ...formattedHours];
            } else {
              acc.push({
                weekDay,
                ...rest,
                openHours: formattedHours,
              });
            }

            return acc;
          }, [])
        : [];
      manipulated._id = promotion._id;
      manipulated.source = promotion?.source;
      setInitialState(manipulated);

      if (promotion.logo) _setImage(promotion.logo);
    }

    setSelectedLocation(promotion?.location);
    setSelectedRecommendation(promotion?.recommendation);
  }, [promotion]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitForm = async (values) => {
    const {
      title = "",
      summary = "",
      description = "",
      bookingEmail = "",
      location = {},
      recommendation = {},
      minCover = "",
      maxCover = "",
      needsConfirmation = true,
      openEnded = false,
      startDate = "",
      endDate = "",
      openHours = [],
    } = values;

    const baseValues = {
      _id: values?._id,
      title,
    };
    let additionalValues = {};
    additionalValues = {
      title,
      summary,
      description,
      bookingEmail,
      location,
      recommendation,
      minCover,
      maxCover,
      needsConfirmation,
      openHours,
      openEnded,
      startDate,
      endDate,
    };
    const updatedValues = { ...baseValues, ...additionalValues };

    await sleep(1000);
    dispatch(setLoading());

    updatedValues.openHours = updatedValues.openHours.flatMap((item) => {
      if (item.openHours.length > 0) {
        return item.openHours.map((time) => ({
          open: time.open,
          close: time.close,
          closed: item.closed,
          weekDay: item.weekDay,
          open_all_day: item.open_all_day,
        }));
      }

      return {
        open: null,
        close: null,
        weekDay: item.weekDay,
        closed: item.closed,
        open_all_day: item.open_all_day,
      };
    });
    // eslint-disable-next-line no-alert
    // values, partner, keyWords, imageBuffer, originalTitle
    dispatch(updatePromotion(updatedValues, values?.logo));
    setPromotionDetailPage(true);
    setDateAndTimeDetailPage(true);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOnePromotion(promotion._id));
    }
  };

  const handleSubmitTranslate = (values) => {
    dispatch(updatePromotionTranslations(values, promotion?._id));
  };

  const handleDetailData = () => {
    switch (tabValue) {
      case 1:
        return (
          <DynamicTranslate
            items={translateItems}
            handleSubmit={handleSubmitTranslate}
            id={promotion?._id}
          />
        );

      default:
        return null;
    }
  };

  const handleRecommendationChange = (value) => {
    if (value && value?._id) {
      setSelectedRecommendation(value);
    } else {
      setSelectedRecommendation({});
    }
  };

  const handleLocationChange = (value, setFieldValue) => {
    if (value && value?._id) {
      setSelectedLocation(value);
    } else {
      setSelectedLocation({});
    }
    setFieldValue("recommendation", {});
    setSelectedRecommendation({});
  };

  function getStepContent(type, formData) {
    let detailPage;
    let setDetailPage;

    switch (type) {
      case "promotionInfo":
        detailPage = promotionDetailPage;
        setDetailPage = setPromotionDetailPage;
        break;
      default:
        detailPage = dateAndTimeDetailPage;
        setDetailPage = setDateAndTimeDetailPage;
    }

    const handleEdit = () => {
      formData.resetForm();
      if (type === "promotionInfo") {
        setPromotionDetailPage(false);
        setDateAndTimeDetailPage(true);
      }
      if (type === "dateAndTimeInfo") {
        setDateAndTimeDetailPage(false);
        setPromotionDetailPage(true);
      }
      setImgDetailPage(true);
    };

    return (
      <PromotionDetail
        // availabilityType={availabilityType}
        type={type}
        formData={formData}
        formRef={formRef}
        initialState={initialState}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        handleEdit={handleEdit}
        selectedRecommendation={selectedRecommendation}
        handleRecommendationChange={handleRecommendationChange}
        selectedLocation={selectedLocation}
        handleLocationChange={handleLocationChange}
        setSelectedRecommendation={setSelectedRecommendation}
        setSelectedLocation={setSelectedLocation}
      />
    );
  }

  // const handleNavigation = (type) => {
  //   let url = null;
  //   if (type === "admin") {
  //     url = `/admin/promotions?`;
  //     navigate(url);
  //   }
  // };
  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/promotions?`;
      navigate(url);
    } else if (type === "partner") {
      url = `/partner/promotions?locationId=${promotion?.location?._id}`;
      navigate(url);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={promotionValidation}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <DynamicHeader
                      user={promotion}
                      image={image}
                      imgDetailPage={imgDetailPage}
                      detailTabs={detailTabs}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                      imageType="logo"
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      {tabValue === 0 && (
                        <ViewEditActions
                          detailPage={imgDetailPage}
                          stateName="promotions"
                          handleEdit={() => {
                            setImgDetailPage(false);
                            setDateAndTimeDetailPage(true);
                            setPromotionDetailPage(true);
                          }}
                          handleClose={() => {
                            _setImage(promotion?.logo);
                            setImgDetailPage(true);
                          }}
                          handleSave={() => {
                            submitForm(values);
                            setImgDetailPage(true);
                          }}
                        />
                      )}
                    </DynamicHeader>
                    {tabValue === 0 ? (
                      <>
                        {["promotionInfo", "dateAndTimeInfo"].map((item, index) => (
                          <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                            <MDBox p={2}>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item size={{ xs: 12 }}>
                                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    {getStepContent(item, {
                                      values,
                                      touched,
                                      formField,
                                      errors,
                                      setFieldValue,
                                      resetForm,
                                    })}
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </Card>
                        ))}
                      </>
                    ) : (
                      handleDetailData()
                    )}
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditPromotion;

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */

// Libraries

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid2";
import MaterialTablePicker from "components/MaterialTablePicker";
import { Formik, Form } from "formik";
import MDBox from "components/MDBox";
import { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import {
  addInvoicesReport,
  clearErrors,
  clearHeaderAndReports,
  clearInvoicesReport,
  setLoading,
} from "store/actions/reportActions";
import DynamicDateRange from "components/DynamicDateRange";
import { isSameDay, subDays } from "date-fns";
import Spinner from "components/Spinner";
import StatusPicker from "components/StatusPicker";
// import { getCities } from "store/actions/cityActions";
import { getPdf, getCsv } from "store/actions/exportAction";
import CurrencyPicker from "components/AutoComplete/CurrencyPicker";
import DestinationPicker from "components/AsyncSelect/DestinationPicker";
import initialValues from "./InvoicesList/schemas/initialValues";
import PdfInvoices from "./PdfInvoices";
import form from "./InvoicesList/schemas/form";
import InvoiceDetailsRow from "./invoicesItemsTable";
import CreditNotePdf from "./CreditNotePdf";

const Invoices = () => {
  const { type } = form.formField;

  const tableRef = createRef();
  const formRef = useRef();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.reports.loading);
  const user = useSelector((state) => state.users.user);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [hideDaterange, setHideDaterange] = useState(true);
  const [selectedCurrency, setSelectedCurrency] = useState({ code: "USD", name: "USD" });

  const [arr, setArr] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [isGenerateButton, setIsGenerateButton] = useState(false);
  const [status, setStatus] = useState(null);
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  useEffect(() => {
    if (dateRange[0]?.startDate && dateRange[0]?.endDate && isGenerateButton) {
      dispatch(setLoading());
      if (selectedCurrency?.name) {
        dispatch(
          addInvoicesReport({
            dateRange,
            selectedDestination,
            status,
            selectedCurrency,
          })
        );
      } else {
        dispatch(
          addInvoicesReport({
            dateRange,
            selectedDestination,
            status,
          })
        );
      }
    }
    setIsGenerateButton(false);
  }, [isGenerateButton, selectedCurrency]);

  const submitForm = async (val, actions) => {
    await sleep(1000);

    dispatch(setLoading());
    dispatch(
      addInvoicesReport({
        dateRange,
        selectedDestination,
        status,
        selectedCurrency,
      })
    );
    // actions.setSubmitting(false);
    // actions.resetForm();
  };

  const handleSubmit = (val, actions) => {
    submitForm(val, actions);
  };

  const handleReset = (val, actions) => {
    setArr([]);
    setSelectedDestination(null);
    // setValCity(null);
    setStatus(null);
    setSelectedCurrency({ code: "USD", name: "USD" });
    setDateRange([
      {
        startDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          7
        ),
        endDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          1
        ),
        key: "selection",
      },
    ]);
  };
  const handleTypeChange = (value) => {
    if (!value) {
      setStatus(null);
    } else {
      setStatus(value);
    }
  };

  const handleDestinationChange = (value) => {
    if (!value) {
      setSelectedDestination(null);
    } else {
      setSelectedDestination(value);
    }
  };

  let filteredURL = "";

  const hasDateRange = dateRange[0]?.startDate && dateRange[0]?.endDate;
  const formattedStartDate = hasDateRange
    ? dateRange[0]?.startDate?.toLocaleDateString("pt-br").split("/").reverse().join("-")
    : "";
  const formattedEndDate = hasDateRange
    ? dateRange[0]?.endDate?.toLocaleDateString("pt-br").split("/").reverse().join("-")
    : "";

  if (hasDateRange) {
    filteredURL = `&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
  }

  if (selectedDestination?.value) {
    filteredURL += `&destination=${selectedDestination.value || ""}`;
  }

  if (status) {
    filteredURL += `&type=${status}`;
  }

  // Add selectedCurrency.name to the query if it exists
  if (selectedCurrency?.name) {
    filteredURL += `&currencyCode=${selectedCurrency.name}`;
  }

  const handleDateChange = (date) => {
    if (date?.selection?.startDate) {
      const newStartDate = date.selection.startDate;
      const newEndDate = isSameDay(date.selection.startDate, date.selection.endDate)
        ? new Date()
        : date.selection.endDate;

      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
    } else {
      const newStartDate = date?.range1?.startDate
        ? date?.range1.startDate
        : date?.selection?.startDate;
      const newEndDate = date?.range1?.endDate ? date?.range1.endDate : date?.selection?.endDate;

      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
    }
  };

  useEffect(() => {
    dispatch(clearHeaderAndReports());
    // setInvoicesRes({});
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid item size={{ xs: 12, lg: 8 }}>
        <Formik
          initialValues={initialValues}
          // validationSchema={currentValidation}
          onSubmit={handleSubmit}
          innerRef={(f) => {
            formRef.current = f;
          }}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form autoComplete="off">
              <Grid container spacing={3}>
                <Grid item size={{ xs: 3 }}>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "transparent",
                      borderRadius: "8px",
                      width: "100%", // Make it full width
                    }}
                  >
                    <DestinationPicker
                      destination={{ label: "Destinations" }}
                      handleChange={handleDestinationChange}
                      setFieldValue={setFieldValue}
                      selectedDestination={selectedDestination || {}}
                    />
                  </MDBox>
                </Grid>
                <Grid item size={{ xs: 3 }}>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "transparent",
                      borderRadius: "8px",
                      width: "100%", // Make it full width
                    }}
                  >
                    <StatusPicker
                      status={type}
                      setStatus={setStatus}
                      statusV={status}
                      setFieldValue={setFieldValue}
                      handleTypeChange={handleTypeChange}
                    />
                  </MDBox>
                </Grid>
                <Grid item size={{ xs: 3 }}>
                  <DynamicDateRange
                    isTodayIncluded
                    defaultValue={dateRange}
                    selectedOption={dateRange}
                    handleDateChange={handleDateChange}
                    hideDaterange={hideDaterange}
                    hideDateRangeFn={setHideDaterange}
                  />
                  {dateRange[0]?.startDate !== null && (
                    <MDBox style={{ fontSize: "small" }}>
                      Selected Date Range: {dateRange[0]?.startDate?.toDateString()} to{" "}
                      {dateRange[0]?.endDate?.toDateString()}
                    </MDBox>
                  )}
                </Grid>
                <Grid item size={{ xs: 6 }}>
                  <MDTypography variant="h6" color="text" fontWeight="regular">
                    Currency
                  </MDTypography>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "white",
                    }}
                  >
                    <CurrencyPicker
                      currency={selectedCurrency}
                      handleSelectCurrency={(event) => {
                        setSelectedCurrency(event);
                      }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  textAlign: "right",
                  marginRight: "0px",
                  marginTop: "20px",
                }}
              >
                {" "}
                <div style={{ marginTop: "20px" }}>
                  <MDButton
                    onClick={(val, actions) => {
                      if (Object.keys(errors).length === 0) {
                        setHideDaterange(true);
                      }
                      setSubmit(true);
                      setIsGenerateButton(true);
                    }}
                    type="submit"
                    variant="gradient"
                    color="dark"
                  >
                    Generate Report
                  </MDButton>
                </div>
                <div style={{ textAlign: "right", marginTop: "20px", marginLeft: "8px" }}>
                  <MDButton
                    onClick={(val, actions) => {
                      setSubmit(false);
                      handleReset(val, actions);
                      setFieldValue("destinations", []);
                      setFieldValue("type", null);
                      setFieldValue("dateRange", {});
                      setStatus(null);
                      setDateRange([
                        {
                          startDate: null,
                          endDate: null,
                          key: "selection",
                        },
                      ]);
                      setSelectedDestination(null);
                    }}
                    type="submit"
                    variant="gradient"
                    color="dark"
                  >
                    Reset Filters
                  </MDButton>
                </div>
              </MDBox>
              {arr.length > 0 && (
                <div
                  style={{
                    width: "100%",
                    height: "21px",
                    marginTop: "10px",
                    backgroundColor: "#d3d3d3",
                  }}
                />
              )}
              <Grid item size={{ xs: 12 }} mt={3}>
                {((selectedDestination?.label && submit === true) ||
                  (status && submit === true) ||
                  (dateRange && submit === true)) && (
                  <MDBox>
                    <MaterialTablePicker
                      key={isGenerateButton}
                      screen="invoices"
                      title="Invoices"
                      asyncTable
                      isActions
                      asyncURL={`${process.env.REACT_APP_API}/invoices?${filteredURL}&`}
                      searchField="invoices?"
                      exportMenuField="invoices"
                      currentUrl="invoices"
                      tableRef={tableRef}
                      columns={[
                        "Title",
                        "Creation Date",
                        "Invoice #",
                        "Customer Name",
                        "Destination Name",
                        "Customer Email",
                        "Source",
                        "Total Amount",
                        "Currency",
                        "AED Revenue",
                      ]}
                      isLoading={loading}
                      // filteringOptions={false}
                      isSearchable={false}
                      actionsName="invoices"
                      sortField="createdAt"
                      direction="desc"
                      detailPanel={(rowData) => (
                        <MDBox sx={{ ml: 6, mr: 4 }}>
                          <InvoiceDetailsRow invoices={rowData?.rowData} />
                        </MDBox>
                      )}
                      actionsRole={user.role === "admin" || user.role === "partner"}
                      actionsOnClick={(item) => {
                        if (item?.type === "refund" && user?.role === "admin") {
                          CreditNotePdf(item);
                        } else PdfInvoices(item);
                      }}
                      isFreeActions={false}
                      isExportable
                      invoiceTable
                      isRowClickable={false}
                      getCsv={(query) => {
                        dispatch(getCsv(`invoices${query}&csv=true`, "InvoicesList"));
                      }}
                    />
                  </MDBox>
                )}
              </Grid>
              <Grid item size={{ xs: 12 }} mt={3}>
                {(!selectedDestination?.value || !status) && submit === false && (
                  <MDBox>
                    <MaterialTablePicker
                      screen="invoices"
                      key={isGenerateButton}
                      title="Invoices"
                      asyncTable
                      isActions
                      // will not pass currencyCode for not until they handle it from backend
                      // asyncURL={`${process.env.REACT_APP_API}/invoices?${
                      //   selectedCurrency?.name ? `currencyCode=${selectedCurrency.name}&` : ""
                      // }`}
                      asyncURL={`${process.env.REACT_APP_API}/invoices?`}
                      searchField="invoices?"
                      exportMenuField="invoices"
                      currentUrl="invoices"
                      tableRef={tableRef}
                      columns={[
                        "Creation Date",
                        "Invoice #",
                        "Customer Name",
                        "Destination Name",
                        "Email ",
                        "Source",
                        "Total Amount",
                        "Currency",
                        "AED Revenue",
                      ]}
                      detailPanel={(rowData) => (
                        <MDBox sx={{ ml: 6, mr: 4 }}>
                          <InvoiceDetailsRow invoices={rowData?.rowData} />
                        </MDBox>
                      )}
                      isLoading={loading}
                      isSearchable={false}
                      actionsName="invoices"
                      actionsRole={user.role === "admin" || user.role === "partner"}
                      sortField="date"
                      direction="desc"
                      actionsOnClick={(item) => {
                        if (item?.type === "refund" && user?.role === "admin") {
                          CreditNotePdf(item);
                        } else PdfInvoices(item);
                      }}
                      isFreeActions={false}
                      isExportable
                      invoiceTable
                      isRowClickable={false}
                      getCsv={(query) => {
                        dispatch(getCsv(`invoices${query}&csv=true`, "InvoicesList"));
                      }}
                    />
                  </MDBox>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>

      <Footer />
      <Spinner loading={loading} />
    </DashboardLayout>
  );
};

export default Invoices;

/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import MDBox from "components/MDBox";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MaterialTableForTags from "components/MaterialTableForTags";
import setAlert from "store/actions/alertActions";
import DynamicTabs from "components/DynamicTabs";
import { setLoading, clearErrors, clearTag } from "store/actions/tagActions";
import { useNavigate, useSearchParams } from "react-router-dom";
import TagAndSubtagDialog from "../new-edit-tag/TagAndSubTagDialog";

const Tags = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const type = searchParams.get("type");

  const detailTabs = [
    { id: 1, title: "RECOMMENDATIONS" },
    { id: 2, title: "TICKETS" },
    { id: 3, title: "REC. & TICKETS" },
    { id: 4, title: "JOBS" },
  ];

  const error = useSelector((state) => state.tags.error);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [idSelected, setIdSelected] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [typeVal, setTypeVal] = useState({ label: "Recommendations", value: "RECOMMENDATIONS" });
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedTag2, setSelectedTag2] = useState(null);

  const handleChange = (event, rowData) => {
    setIdSelected(rowData?._id);
    setDialogOpen(true);
  };

  const resetSelectedTags = () => {
    setSelectedTag(null);
    setSelectedTag2(null);
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    resetSelectedTags();

    if (newValue === 0) {
      dispatch(setLoading());
      setTypeVal({ type: "Recommendations", value: "RECOMMENDATIONS" });
      navigate(`?type=RECOMMENDATIONS`);
    }
    if (newValue === 1) {
      setTypeVal({ label: "Tickets", value: "TICKETS" });
      navigate(`?type=TICKETS`);
    }
    if (newValue === 2) {
      setTypeVal({ label: "Rec. & Tickets", value: "BOTH" });
      navigate(`?type=BOTH`);
    }
    if (newValue === 3) {
      setTypeVal({ label: "Jobs", value: "JOBS" });
      navigate(`?type=JOBS`);
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error?.includes("duplicate") ? "Tag already exists" : error, "error"));
      dispatch(clearErrors());
    }
  }, [error, dispatch]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2}>
        {" "}
        <Grid item size={{ xs: 12, md: "auto" }}>
          <MDBox>
            <DynamicTabs
              detailTabs={detailTabs}
              tabValue={tabValue}
              handleSetTabValue={handleSetTabValue}
            />
          </MDBox>
        </Grid>
        <Grid item size={{ xs: 12 }}>
          <MDBox display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setDialogOpen(true);
                dispatch(clearTag());
              }}
            >
              Add Tag
            </Button>
          </MDBox>
          <MDBox mt={2}>
            <MaterialTableForTags
              currentType={type || "RECOMMENDATIONS"}
              setDialogOpen={setDialogOpen}
              handleChange={handleChange}
              resetSelectedTags={resetSelectedTags}
              selectedTag={selectedTag}
              setSelectedTag={setSelectedTag}
              selectedTag2={selectedTag2}
              setSelectedTag2={setSelectedTag2}
            />
          </MDBox>
        </Grid>
      </Grid>
      <TagAndSubtagDialog
        idSelected={idSelected}
        open={dialogOpen}
        typeVal={typeVal}
        tabValue={tabValue}
        setTypeVal={setTypeVal}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
      <Footer />
    </DashboardLayout>
  );
};

export default Tags;
